import React, {
  useEffect,
  useState,
  KeyboardEvent,
  MouseEvent,
  ReactNode,
} from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ArrowRight from "../../../assets/right-arrow.svg";
import ArrowLeft from "../../../assets/left-arrow.svg";
import ArrowDown from "../../../assets/down-arrow.svg";

interface CustomArrowProps {
  onClick: () => void;
  src: ReactNode;
  right?: boolean;
}

const CustomArrow: React.FC<CustomArrowProps> = ({ onClick, src, right }) => {
  return (
    <div
      role="button"
      tabIndex={0}
      className="react-multi-carousel-custom-arrow"
      style={{
        right: right ? 0 : undefined,
      }}
      onClick={() => onClick()}
      onKeyDown={(e: KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") onClick();
      }}
    >
      {src}
    </div>
  );
};

interface CustomDotsProps {
  index: number;
  onClick: (event: MouseEvent<HTMLLIElement>) => void;
  active: boolean;
  value: { id: string; logo?: { images: { fallback: { src: string } } } }[];
}

const CustomDots: React.FC<CustomDotsProps> = ({
  index,
  onClick,
  active,
  value,
}) => {
  return (
    <li
      key={value[index].id}
      onClick={onClick}
      className="dot-list-item"
      aria-hidden="true"
    >
      {active && (
        <div className="react-multi-carousel-arrow-down">
          <ArrowDown />
        </div>
      )}
      <img
        src={value[index].logo?.images.fallback.src}
        className={active ? "active" : "inactive"}
        alt=""
      />
    </li>
  );
};

interface CustomCarouselProps {
  children: ReactNode;
  logo: { id: string; logo?: { images: { fallback: { src: string } } } }[];
  [x: string]: any;
}

export default function CustomCarousel({
  children,
  logo,
  ...attributes
}: CustomCarouselProps) {
  const [carouselDotList, setCarouselDotList] =
    useState<HTMLUListElement | null>(null);
  const [carouselDotListItem, setCarouselDotListItem] =
    useState<HTMLLIElement | null>(null);
  const [slideIndex, setSlideIndex] = useState(0);
  const count = React.Children.count(children);

  const responsive = {
    device: {
      breakpoint: { max: 4000, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    if (document) {
      setCarouselDotListItem(
        document.querySelector(".dot-list-item") as HTMLLIElement
      );
      setCarouselDotList(
        document.querySelector(
          ".react-multi-carousel-dot-list"
        ) as HTMLUListElement
      );
    }
  }, [slideIndex]);

  useEffect(() => {
    if (carouselDotListItem) {
      if (carouselDotListItem.style) {
        carouselDotListItem.style.marginLeft = `${
          15 -
          slideIndex *
            ((count * 150 - (carouselDotList?.clientWidth || 0)) / (count - 1))
        }px`;
      }
    }
  }, [carouselDotListItem, slideIndex, carouselDotList?.clientWidth, count]);
  return (
    <Carousel
      responsive={responsive}
      customTransition="transform 500ms ease-in-out"
      arrows
      customLeftArrow={
        <CustomArrow
          src={<ArrowLeft />}
          onClick={function (): void {
            throw new Error("Function not implemented.");
          }}
        />
      }
      customRightArrow={
        <CustomArrow
          src={<ArrowRight />}
          right
          onClick={function (): void {
            throw new Error("Function not implemented.");
          }}
        />
      }
      showDots
      customDot={
        <CustomDots
          value={logo}
          index={0}
          onClick={function (
            event: React.MouseEvent<HTMLLIElement, globalThis.MouseEvent>
          ): void {
            throw new Error("Function not implemented.");
          }}
          active={false}
        />
      }
      renderDotsOutside
      dotListClass=""
      itemClass="react-multi-carousel__item"
      beforeChange={(nextSlide) => {
        setSlideIndex(nextSlide);
      }}
      {...attributes}
    >
      {children}
    </Carousel>
  );
}
